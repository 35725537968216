<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <form @submit.prevent="form">
      <div class="row">
        <div class="col-12 pt-5">
          <h6>
            <v-icon class="pb-1" v-text="'mdi-numeric-1-box-outline'" />
            {{ $t("position.information_position") }}
          </h6>
        </div>
        <div class="col-md-4">
          <validation-provider
            v-slot="{ errors }"
            name="name"
            rules="required|max:200"
          >
            <v-text-field
              v-model="model.name"
              :counter="100"
              :error-messages="errors"
              :label="$t('label.name')"
              oninput="this.value = this.value.toUpperCase()"
              required
            />
          </validation-provider>
        </div>
        <div class="col-md-8">
          <validation-provider
            v-slot="{ errors }"
            name="description"
            rules="required|max:255"
          >
            <v-text-field
              v-model="model.description"
              oninput="this.value = this.value.toUpperCase()"
              :counter="200"
              :error-messages="errors"
              :label="$t('label.description')"
              required
            />
          </validation-provider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-right">
          <button
            v-if="model.idPosition != ''"
            class="btn btn-outline-success"
            type="submit"
            :disabled="invalid || sending"
          >
            <i class="feather icon-save mr-1"></i> {{ $t("global.edit_position") }}
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="submit"
            :disabled="invalid || sending"
          >
            <i class="feather icon-save mr-1"></i> {{ $t("global.save_position") }}
          </button>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { UtilFront } from "@/core";
import { EmployeeRequest } from "@/core/request";
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  data() {
    return {
      model: {
        idPosition: "",
        name: "",
        description: "",
      },
      sending: false,
    };
  },
  methods: {
    form() {
      this.model.idPosition != "" ? this.updatePosition() : this.addPosition();
    },
    updatePosition() {
      if (!this.sending) {
        this.sending = true;
        this.loading = UtilFront.getLoadding();
        EmployeeRequest.updatePosition({
          idPosition: this.model.idPosition,
          name: this.model.name.trim(),
          idBranchOffice: 1,
          description: this.model.description.trim(),
          idUser: UtilFront.getDataUser().idUser,
        })
          .then((response) => {
            let { success, message } = response.data.data;
            if (success) {
              this.$emit("alert", "success", "Update_Success_0001");
              this.$emit("changeAction", 1);
            } else {
              this.$emit("alert", "error", message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.$emit("alert", "internal");
          })
          .finally(() => {
            this.loading.hide();
            this.sending = false;
          });
      }
    },
    addPosition() {
      if (!this.sending) {
        this.sending = true;
        this.loading = UtilFront.getLoadding();
        EmployeeRequest.addPosition({
          name: this.model.name.trim(),
          idBranchOffice: 1,
          description: this.model.description.trim(),
          idUser: UtilFront.getDataUser().idUser,
        })
          .then((response) => {
            let { success, message } = response.data.data;
            if (success) {
              this.$emit("alert", "success", "Add_Success_0001");
              this.$emit("changeAction", 1);
            } else {
              this.$emit("alert", "error", message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.$emit("alert", "internal");
          })
          .finally(() => {
            this.sending = false;
            this.loading.hide();
          });
      }
    },
    cancel() {
      this.model.name = this.model.description = this.model.idPosition = "";
      this.$refs.observer.reset();
    },
    reloadComponentUpdate(item) {
      this.model = item;
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
};
</script>
