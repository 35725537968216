<template>
  <div class="p-2">
    <v-data-table
      :headers="headers"
      :items="dataList"
      :search="search"
      height="auto"
      fixed-header
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      dense
    >
      <template v-slot:top>
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <v-text-field
              v-model="search"
              :label="$t('table.search')"
            ></v-text-field>
          </div>
        </div>
      </template>

      <template v-if="isUpdate" v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="updateItem(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi mdi-square-edit-outline
            </v-icon>
          </template>
          <span>{{ $t("table.edit") }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <ButtonTableComponent v-on:excelTable="excelTable" />
  </div>
</template>

<script>
import { UtilFront, PermissionConstants } from "@/core";
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";
import ButtonTableComponent from "@/common/table/ButtonTableComponent";

export default {
  props: {
    headers: {},
    permission: false,
    dataList: null,
    isUpdate: false,
  },
  data() {
    return {
      search: "",
      permissions: PermissionConstants,
    };
  },
  methods: {
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    updateItem(item) {
      this.$emit("updateItem", item);
    },
    excelTable() {
      this.$emit( "excelTable" );
    },
  },
  components: {
    ButtonPrintComponent,
    ButtonTableComponent,
  },
};
</script>
