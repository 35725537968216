<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <h4 class="page-title">{{ $t("position.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("position.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a @click="changeAction(1)">{{
                    $t("position.configuration")
                  }}</a>
                </li>
                <li class="breadcrumb-item">{{ $t(title) }}</li>
              </ol>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
            <button
              class="btn btn-outline-success"
              v-if="
                withPermissionName(permissions.CAT_POS_ADD_01) && showAction(1)
              "
              @click="changeAction(2)"
            >
              <i class="ti-plus"></i> {{ $t("global.add_position") }}
            </button>
            <button
              class="btn btn-outline-success"
              v-if="showAction(2) || showAction(3)"
              @click="changeAction(1)"
            >
              <i class="ti-arrow-left mr-2"></i> {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>

      <div class="contentbar">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="card-title">
                      <i class="fa fa-bookmark mr-2" aria-hidden="true"></i>
                      {{ $t(title) }}
                    </h5>
                  </div>
                </div>
              </div>
              <EmployeeTableComponent
                v-on:excelTable="excelTable"
                :isUpdate="withPermissionName(permissions.CAT_POS_EDIT_02)"
                v-if="withPermissionName(permissions.POSITIONS)"
                v-show="showAction(1)"
                :dataList="positions"
                :headers="positionHeaders"
                @updateItem="edit"
              />
              <div class="card-body" v-show="showAction(2) || showAction(3)">
                <AddPositionComponent
                  ref="formAddCargo"
                  @alert="alert"
                  @changeAction="changeAction"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EmployeeRequest, ExcelRequest } from "@/core/request";
import { UtilFront, PermissionConstants } from "@/core";
import EmployeeTableComponent from "@/components/employee/EmployeeTableComponent";
import AddPositionComponent from "@/components/employee/AddPositionComponent";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import { CARGO } from "@/core/DataTableHeadersLocale";

export default {
  data() {
    return {
      /* Table */
      positionHeaders: CARGO(),
      action: 1,
      departmentList: [],
      personalList: [],
      positions: [],
      /* Permisos */
      permissions: PermissionConstants,
    };
  },
  computed: {
    title() {
      return this.action == 1
        ? "position.position"
        : this.action == 2
        ? "position.register_position"
        : "position.edit_position";
    },
  },
  methods: {
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    edit(item) {
      this.changeAction(3);
      this.$refs.formAddCargo.reloadComponentUpdate(item);
    },
    showAction(action) {
      return this.action == action;
    },
    changeAction(action) {
      this.action = action;
      this.action == 1
        ? this.listAll()
        : this.action == 2
        ? this.reload()
        : null;
    },
    listAll() {
      this.loading = UtilFront.getLoadding();
      EmployeeRequest.listAllPosition({
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: 1,
      })
        .then((response) => {
          let { success, data, message } = response.data.data;
          success ? (this.positions = data) : this.alert("error", message);
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    reload() {
      this.$refs.formAddCargo.cancel();
    },
    excelTable() {
      this.$toast.success("Generando documento Xlsx");
      ExcelRequest.reportPositions({
        idUser: UtilFront.getDataUser().idUser,
      });
    },
  },
  created() {
    this.listAll();
  },
  watch: {
    "$i18n.locale": function() {
      this.positionHeaders = CARGO();
    },
  },
  components: {
    EmployeeTableComponent,
    AddPositionComponent,
    AlertMessageComponent,
  },
};
</script>
