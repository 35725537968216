<template>
  <div>
    <div class="col-md-6">
      <div class="invoice-footer-btn">
        <button class="btn btn-outline-secondary py-1 no-print" @click=" print() "><i class="feather icon-printer mr-2"></i> {{$t('table.print')}}</button>
        <button class="btn btn-success py-1 ml-3 no-print" @click="excelTable"><i class="fa fa-file-excel-o mr-2"></i> {{$t('table.excel')}}</button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
    methods: {
        print(){
            window.print();
        },
        excelTable(){
            this.$emit( "excelTable" );
        }
    },

};
</script>



